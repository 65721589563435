@import '@/assets/scss/mixin.scss';

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card_text {
  text-align: start;
  width: 100%;

  @include respond-to(720px) {
    padding-right: 10px;
    padding-left: 16px;
  }
}
